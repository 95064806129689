import { QuickPayMode } from "./ISite";

export interface ICreditData {
    cardNumber?: string;
    cardName?: string;
    cardExpiry?: string;
    cardCvc?: number;
    overrideFeePercent?: number;
    overrideFeeFlat?: number;
}

export interface IAccountDetail {
    primaryReference?: any;
    customFields?: [];
}

export interface IPersonalData {
    name?: string;
    address?: string;
    email?: string;
    phone?: string;
    postal?: string;
    notes?: string;
    dateOfBirth?: string;

    accountHolderSurnameOrCompanyName?: string;
    isCompany?: boolean;

    payeeCustom1?: string;
    payeeCustom2?: string;
    payeeCustom3?: string;

    accountDetails?: IAccountDetail;
}

export enum PaymentType {
    Single = 0,
    FutureDate = 1,
}

export interface IPaymentData {
    totalDebt?: number;
    paymentType: PaymentType;
    singlePaymentAmount?: number;
    paymentFutureDate?: string;
    futureDatePaymentAmount?: number;
    futureDatePaymentSetupAmount?: number;
}

export default interface IPayment {
    personalData?: IPersonalData;
    creditData?: ICreditData;
    paymentData: IPaymentData;
    sendQuickPay?: QuickPayMode;
}

export interface IPaymentResponse {
    transactionId: number;
}
